import React, { useEffect, useState } from "react";
import { GET } from '../../services/request';
import slug from 'slugify';
import { useUser, Types } from '../../context/user';
import { useSidebarToggle } from '../../context/sidebarToggle';
import Router from 'next/router';
const Sidebar = () => {
  const [isOpenCategoryMenu, setOpenCategoryMenu] = useState(false);
  const [sidebarCategory, setSidebarCategory] = useState([]);
  const [sidebarCategoryFilter, setSidebarCategoryFilter] = useState([]);
  const [search, setSearch] = useState('');
  const { state, dispatch: dispatchUser } = useUser();
  const { state: { isOpenMenu }, dispatch: dispatchSidebarToggle } = useSidebarToggle();
  const [searchbill, setSearchBill] = useState('');
  const user = state ? state.user : null;
  const tokenUser = state ? state.token : '';
  const [errorSearchBill, setErrorSearchBill] = useState('');
  const [styleAnimation, setAnimation] = useState({
    shake: false
  });
  const toggle = (type) => {
    dispatchSidebarToggle({
      type
    })
  };
  const fetchCategories = async () => {
    try {
      const categories = await GET('/categories/with-child-categories');
      const normalizeCategories = categories.map((category) => ({
        id: category.id,
        name: category.name,
        slug: category.slug,
        childCategories: category.ChildCategories || []
      }))
      setSidebarCategory(normalizeCategories);
      setSidebarCategoryFilter(normalizeCategories);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCategories();
  }, [])

  const onSearch = (e) => {
    const search = e.target.value;
    setSearch(search);
    const searchSlug = slug(search.toLowerCase());
    const newSidebarCategoryFilter = sidebarCategory.filter(
      cate => slug(cate.name.toLowerCase()).indexOf(searchSlug) > -1
        || (cate.childCategories || []).filter(
          childCate => slug(childCate.name.toLowerCase()).indexOf(searchSlug) > -1
        ).length > 0
    );
    setSidebarCategoryFilter(newSidebarCategoryFilter);
  };

  const backMenu = () => {
    setSearch('')
    setOpenCategoryMenu(false);
    setSidebarCategoryFilter(sidebarCategory)

  }

  const onSubmitSearch = async(e) => {

    e.preventDefault();
    try {
      const bill =  await GET(`/bills/${searchbill}`, {
        headers: {
          authentication: tokenUser
        }
      });
      setAnimation({
        ...styleAnimation,
        shake: true
      })
      setTimeout(() => {
        setAnimation({
          ...styleAnimation,
          shake: false
        })
      }, 500);
      toggle(false);
      backMenu();
      Router.push(`/history/${searchbill}`)
    } catch (data) {
      setErrorSearchBill(data?.data?.error);
    }
  }

  return <>
    <div className={`sidenav-black-overlay ${isOpenMenu ? 'active' : ''}`} onClick={() => toggle(false)}></div>
    <div className={`suha-sidenav-wrapper ${isOpenMenu ? 'nav-active' : ''}`} id="sidenavWrapper">
      <div className="sidenav-profile">
        <div className="user-info">
          {
            user ? <> <h6 className="user-name mb-0">Xin chào, {user ? user.name : ''}</h6>
              <small className="available-balance">Thành viên vip</small>
            </>
              : <><h6 className="user-name mb-0">Xin chào quý khách</h6>
                <small className="available-balance">Đăng ký để được hưởng ưu đãi</small></>
          }

        </div>
      </div>
      <ul className="sidenav-nav ps-0">
        <li>
          <div className="top-search-form">
            <form onSubmit={onSubmitSearch} className={`${styleAnimation.shake ? 'shake': ''}`}>
              <input value={searchbill} onChange={(e) => { 
                setSearchBill(e.target.value) 
                setErrorSearchBill('')}
                } onBlur={(e) => setSearchBill(e.target.value)} className="form-control search-bill" type="search" placeholder="Tìm kiếm đơn hàng" />
              <button aria-label="tìm kiếm" type="submit" disabled={searchbill ? false : true} ><i className="fa fa-search"></i></button>
            </form>
          </div>
        </li>
        <p className={`my-0 error text-center text-orange ${errorSearchBill ? '' : 'd-none'}`}>{errorSearchBill ? errorSearchBill : ''}</p>
        <li><a className="sidebar-link" onClick={() => {
              toggle(false);
              backMenu();
              Router.push('/')}
            }><i className="fa fa-home"></i>Trang chủ</a></li>
        <li className="suha-dropdown-menu sidebar-link"><a onClick={() => setOpenCategoryMenu(true)}><i className="fa fa-bookmark"></i>Danh mục</a></li>
        {user ?
          <>
            <li><a className="sidebar-link" onClick={() => {
              toggle(false);
              backMenu();
              Router.push('/user')}
            }><i className="fa fa-user-o"></i>Thông tin của tôi</a></li>
            <li><a className="sidebar-link" onClick={() => {
              toggle(false);
              backMenu();
              Router.push('/history')}
            }><i className="fa fa-cart-arrow-down"></i>Đơn hàng của tôi</a></li>
            <li><a className="sidebar-link" onClick={() => {
              dispatchUser({
                type: Types.LOGOUT,
                item: {}
              });
              Router.push('/')
            }}><i className="fa fa-power-off"></i>Đăng xuất</a></li>
          </>
          : <>
            <li><a className="sidebar-link" onClick={() => {
              toggle(false);
              backMenu();
              Router.push('/login')}
            }><i className="fa fa-user"></i>Đăng nhập</a></li>
            <li><a className="sidebar-link" onClick={() => {
              toggle(false);
              backMenu();
              Router.push('/register')}
            }><i className="fa fa-user-plus"></i>Đăng ký</a></li>
          </>
        }

      </ul>
    </div>

    <>
      <div
        className={`sidenav-black-overlay ${isOpenCategoryMenu ? "active" : ""}`}
        onClick={() => {
          toggle(false);
          backMenu();
        }}
      ></div>
      <div
        className={`category-slider suha-sidenav-wrapper ${isOpenCategoryMenu ? "nav-active" : ""}`}
        id="sidenavCategory"
      >
        <div className="sidenav-profile">
          <a className="btn-back" onClick={backMenu}><i className="fa fa-chevron-left"></i></a>
          <div className="user-info">
            <div className="top-search-form" >
              <div className="form">
                <input value={search} onChange={onSearch} 
                  className="form-control" type="search" placeholder="Tìm danh mục"
                  style={{width: '100%'}}  
                />
                <button aria-label="tìm kiếm" type="button" disabled><i className="fa fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
        <ul className="sidenav-nav ps-0">
          {sidebarCategoryFilter.map((item) => 
              <React.Fragment key={item.id}>
                <li style={{ cursor: 'pointer' }}>
                  <h1 className="sidebar-link" onClick={() => {
                    Router.push(`/categories/${item.slug}`)
                  }}>
                    {item.name}
                  </h1>
                </li>
                <li>
                  <ul className="sidebar-child">
                    {
                      item.childCategories && item.childCategories.map((chilItem) =>
                        <li style={{ cursor: 'pointer' }} key={chilItem.id}><h1 onClick={() => {
                          Router.push(`/categories/${chilItem.slug}`)
                        }} className="sidebar-link">{chilItem.name}</h1></li>
                      )
                    }
                  </ul>
                </li>
              </React.Fragment>
            )
          }
        </ul>
      </div>
    </>
  </>
}

export default Sidebar